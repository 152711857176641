.textile p {
  margin: 1em 0;
}

.textile ul, .textile ol {
  padding-left: 2em;
}

.textile h1 {
  font-size: 140%; 
  border: none;
}

.textile h2 {
  font-size: 130%; 
  border: none;
}

.textile h3 {
  font-size: 120%; 
}

.textile h4 {
  font-size: 110%; 
}

.textile h5, .textile h6 {
  font-size: 100%; 
}

.textile blockquote {
  padding-left: 15px;
}